import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'grommet';

const InputNativeSelect = ({
  size,
  padding,
  children,
  options = [],
  style,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <select
      style={{
        fontFamily: theme.global.font.family,
        fontSize: theme.text[size] ? theme.text[size].size : size,
        fontWeight: 500,
        letterSpacing: '1px',
        textRendering: 'optimizeLegibility',
        textTransform: 'uppercase',
        outline: 0,
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 0,
        width: '100%',
        height: '100%',
        color: '#000',
        padding: padding || 0,
        MozTextAlignLast: 'center',
        textAlignLast: 'center',
        ...style,
      }}
      {...rest}
    >
      {options.length > 0 &&
        options.map((o) => (
          <option value={o.value} key={o.value}>
            {o.label}
          </option>
        ))}
      {children}
    </select>
  );
};

InputNativeSelect.propTypes = {
  size: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  options: PropTypes.array,
};

export default InputNativeSelect;
