import React, { useState, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { get, head, find } from '../../lib/nodash';
import { Box, Stack, Heading, ThemeContext } from 'grommet';
import { FormDown } from 'grommet-icons';

import ButtonAddToCart from '../ButtonAddToCart';
import InputNativeSelect from '../Inputs/InputNativeSelect';
import { getVariantPrice } from '../../lib/product';
import useBuybarUi from './useBuybarUi';

const ProductBuyOptionsMultiVariant = ({
  variants,
  productTitle,
  transformVariantLabel,
}) => {
  const [variantId, setVariantId] = useState(get('shopifyId', head(variants)));
  const { buybarRef, isAtTop } = useBuybarUi();
  const options = variants;
  const theme = useContext(ThemeContext);

  return (
    <Box
      direction="row"
      border={{ side: 'all', size: 'small' }}
      align="center"
      background={isAtTop ? 'transparent' : 'white'}
      ref={buybarRef}
    >
      {productTitle && !isAtTop && (
        <Box
          pad="medium"
          flex={{ grow: 1 }}
          border={{ side: 'right', size: 'small' }}
          fill="vertical"
        >
          <Heading level={5}>{productTitle}</Heading>
        </Box>
      )}
      <Stack fill="vertical" guidingChild="last">
        <Box
          fill
          align="center"
          flex={true}
          justify="between"
          direction="row"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Box>
            <FormDown color="black" />
          </Box>
        </Box>
        <InputNativeSelect
          value={variantId}
          name="variantId"
          onChange={(e) => {
            setVariantId(e.target.value);
          }}
          style={{
            padding: `10px ${theme.global.edgeSize.large} 10px  ${theme.global.edgeSize.xlarge}`,
            background: 'transparent',
          }}
        >
          {options.map((x) => (
            <option key={x.shopifyId} value={x.shopifyId}>
              {transformVariantLabel ? transformVariantLabel(x.title) : x.title}
            </option>
          ))}
        </InputNativeSelect>
      </Stack>
      <ButtonAddToCart
        variantId={variantId}
        quantity={1}
        label={`${getVariantPrice(
          find({ shopifyId: variantId }, variants)
        )} Add to Cart`}
        size="small"
      />
    </Box>
  );
};

ProductBuyOptionsMultiVariant.propTypes = {
  variants: PropTypes.array.isRequired,
  productTitle: PropTypes.string,
  transformVariantLabel: PropTypes.func,
};

export default memo(ProductBuyOptionsMultiVariant);
